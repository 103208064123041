import { ILiteEvent, Notis, PageDetails, StateService, ThemeMode, ToastInfo, LoginInfo, LiteEvent } from '@glideroggan/common';
import { RouterView } from '../router/router-view';
RouterView
import { FASTElement, customElement, html } from '@microsoft/fast-element';
import { Modal } from '@glideroggan/modal';


const template = html/*html*/`
<style>
    :host-context(body.dark-theme) {
        --background-color: #111827;
    }
</style>
<slot header name="header">header</slot>
<slot menu name="menu">menu</slot>
<slot content name="content">content</slot>
<slot modals name="modals">modals</slot>
<slot toast name="toast">toast</slot>
`

@customElement({
    name: 'shell-app',
    template: template

})
export class Shell extends FASTElement {
    contentSlot: HTMLSlotElement;
    modalSlot: HTMLSlotElement;
    constructor() {
        super();

        this.addEventListener('login-success', this.handleLoginSuccess.bind(this));
        this.addEventListener('navigate', this.handleNavigate.bind(this));
        this.addEventListener('logout', this.handleLogout.bind(this));
        this.addEventListener('toast', this.handleToast.bind(this));
        this.addEventListener('dark', this.handleDark.bind(this));

        // listen for modal openings
        this.addEventListener('profile-modal-open', this.onModalOpening.bind(this))
        // this.addEventListener('modal-close', (e: Event) => this.onModalClosing(e))

        window.onpopstate = this.popState.bind(this);
    }
    async connectedCallback() {
        super.connectedCallback();

        StateService.registerShell(this)

        this.contentSlot = this.shadowRoot!.querySelector('[content]') as HTMLSlotElement;
        this.contentSlot.addEventListener('slotchange', this.contentSlotChanged.bind(this));
        this.modalSlot = this.shadowRoot!.querySelector('[modals]') as HTMLSlotElement;

        if (!StateService.isLoggedIn) {
            const router: RouterView = this.contentSlot.assignedElements()[0] as unknown as RouterView
            router.navigateToPage({ page: '#landing' })
        };
    }
    private async onModalOpening() {
        console.warn('why does this exists?')
        // console.debug('profile-modal-open')
        const commonModal = await import('@glideroggan/modal')
        const changeProfile = await import('../change-profile.component')
        // add the common modal to DOM and place the change-profile component in the slot
        const modal = new commonModal.Modal()
        // TODO: we should take what component to import from the event
        const changeProfileComponent = new changeProfile.ChangeProfile()
        // TODO: where do we call to populate this component with data?
        // await changeProfileComponent.init()
        modal.appendChild(changeProfileComponent)
        modal.setAttribute('slot', 'modals')
        // add the modal on the content slot
        this.appendChild(modal)

        // open modal
        modal.show()
    }
    // private async onModalClosing(e: Event) {
    //     // TODO: we not using this anymore?
    //     // TODO: we should find the correct modal to close here
    //     // const modal = this.modalSlot.assignedElements()[0] as unknown as Modal
    //     // modal.hide()
    //     // // remove the modal from the DOM
    //     // this.removeChild(modal)
    // }
    protected onLogin: ILiteEvent<LoginInfo> = new LiteEvent<LoginInfo>();
    protected onNavigate: ILiteEvent<PageDetails> = new LiteEvent<PageDetails>();
    protected onLogout: ILiteEvent<void> = new LiteEvent<void>();
    protected onToast: ILiteEvent<ToastInfo> = new LiteEvent<ToastInfo>();
    protected onNotis: ILiteEvent<Notis> = new LiteEvent<Notis>();
    get logIn(): ILiteEvent<LoginInfo> { return this.onLogin.expose(); }
    get navigate(): ILiteEvent<PageDetails> { return this.onNavigate.expose(); }
    get logOut(): ILiteEvent<void> { return this.onLogout.expose(); }
    get toast(): ILiteEvent<ToastInfo> { return this.onToast.expose(); }
    get notis(): ILiteEvent<Notis> { return this.onNotis.expose(); }
    private async popState(event: PopStateEvent) {
        console.debug('popstate: ', event.state)
        console.debug('popstate: ', window.location.hash.slice(1))
        if (window.location.hash.slice(1) == 'login') {
            this.onLogout.trigger();
        }
    }
    private async contentSlotChanged(e: Event) {
        // query for the slotted content and cast it to a router-view type
        const routerView = this.contentSlot.assignedElements()[0] as unknown as RouterView;
        const route = window.location.hash;
        if (route == '' || !StateService.isLoggedIn) {
            await routerView.navigateToPage({ page: '#landing' });
        } else {
            await routerView.navigateToPage({ page: route });
        }

    }
    private async handleDark(event: CustomEventInit) {
        const body = document.querySelector('body') as HTMLElement;
        if (body.classList.contains('dark-theme')) {
            body.classList.remove('dark-theme');
            StateService.settings.setTheme(ThemeMode.Normal);
        } else {
            body.classList.add('dark-theme');
            StateService.settings.setTheme(ThemeMode.Dark);
        }
    }
    handleLoginSuccess(event: CustomEventInit) {
        // console.log('handleLoginSuccess: ', event.detail)
        this.onLogin.trigger(event.detail);

    }
    handleToast(event: CustomEventInit) {
        // console.log('handleToast: ', event.detail)
        this.onToast.trigger(event.detail);
    }
    handleLogout(event: CustomEventInit) {
        this.onLogout.trigger(event.detail);
    }
    handleNavigate(event: CustomEventInit<PageDetails>) {
        // console.log('handleNavigate: ', event.detail)
        this.onNavigate.trigger(event.detail);
    }
}

