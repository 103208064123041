import { Achievement, StateService } from "@glideroggan/common";
import { AchievementCard } from "../../components/achievement-card/achievement-card";
import { FASTElement, css, customElement, html, observable, repeat, when } from "@microsoft/fast-element";
AchievementCard

// interface AchievementViewerModel {
//     achivement: Achievement
//     users: User[]
// }


const template = html<AchievementViewer>`
<div list class="container">
    ${when(x => x.ready, html`
        ${repeat(x => x.achivements, html<Achievement>`<achievement-card :model="${x => x}" ></achievement-card>`)}
    `)}
</div>
`

const styles = css`
.container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    height: 100%;
    gap: 1rem;
}
`

// TODO: maybe these pages should be composed from a page base
@customElement({
    name: 'achievement-viewer-page',
    template,
    styles
})
export class AchievementViewer extends FASTElement {
    @observable ready: boolean = false
    @observable achivements: Achievement[] = []
    constructor() {
        super();
    }

    adoptedCallback() {
        console.log('adopted')
    }
    
    // BUG: achievements isn't coming with the progress-list, when navigating from the dashboard home
    async connectedCallback() {
        super.connectedCallback();
    
        
        this.achivements = await StateService.achievements.getAchievements(this);
        // console.log('achievement-viewer', this.achivements)


        this.ready = true
        this.$emit('loaded')   
    }
}