import { IShellServices, StateService, User } from "@glideroggan/common"
import { NotificationCom } from "../notification/notification";
NotificationCom
import { ProfileMenu } from "./profileMenu"
ProfileMenu
import { UserCard } from "../user-card.component";
UserCard
import { FASTElement, Observable, customElement, html, observable, when } from "@microsoft/fast-element";


import { styles } from './header.css';
import { template } from "./header.template";

@customElement({
    name: 'header-com',
    template: template,
    styles
})
export class Header extends FASTElement {
    toggleDark(): any {
        const ev = new CustomEvent('dark', { bubbles: true, composed: true });
        this.dispatchEvent(ev);
    }
    @observable loggedIn: boolean = false;
    @observable ready: boolean = false;
    @observable user: User;
    @observable translations: any = {};
    constructor() {
        super();
        
        StateService.language.languageChange.on(this.handleLanguageChange.bind(this));
        this.addEventListener('profile-menu-initialized', async (e: Event) => {
            e.stopPropagation()
            const menuData = await StateService.settings.getProfileMenuData()
            const dropdownMenu = this.shadowRoot!.querySelector('profile-menu') as ProfileMenu
            dropdownMenu.data = menuData
        })
        this.addEventListener('profile-modal-open', async (e: Event) => {
            this.toggleProfileMenu();
        })

        StateService.shellConnected.on((services?: IShellServices) => this.registerWithShell(services!))
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        const lang = await StateService.language.getLanguage();
        this.translations = await StateService.language.getTranslations(lang);

        if (StateService.currentUser !== null) {
            this.user = StateService.currentUser
        }

        this.loggedIn = StateService.isLoggedIn
        this.ready = true;
    }
    
    toggleProfileMenu(): void {
        const dropdownMenu = this.shadowRoot!.querySelector('profile-menu') as ProfileMenu
        dropdownMenu.classList.toggle('hidden')
    }
    async registerWithShell(services: IShellServices): Promise<void> {
        services.logIn.on(this.handleLogin.bind(this));
        services.logOut.on(this.handleLogout.bind(this));
    }
    async handleLanguageChange(lang?: string) {
        this.translations = await StateService.language.getTranslations(lang!);
        //  update template
        Observable.notify(this, 'translations')

        // update profile menu
        const menuData = await StateService.settings.getProfileMenuData()
        const dropdownMenu = this.shadowRoot!.querySelector('profile-menu') as ProfileMenu
        dropdownMenu.data = menuData
    }
    handleLogin() {
        this.user = StateService.currentUser as User
        this.loggedIn = StateService.isLoggedIn
    }
    handleLogout() {
        this.loggedIn = StateService.isLoggedIn
    }
}
