import { FASTElement, attr, css, customElement, html } from '@microsoft/fast-element';

const styles = css`
.hidden {
  display: none !important;
}
::slotted(*){
  justify-content: center;
  cursor: pointer;
  user-select: none;            
}

#value::before {
  content: '';
}
.selected::after {
  content: "✔️";
  position: absolute;
  right: 0;
  color: #ff2525;
}
`
// BUG: why is it only the actual text that triggers the click?
const template = html<ProfileMenuItem>`
<div 
  class="item">
    <div id="value" class="${x => x.selected !== null ? 'selected' : ''}">${x => x.value}</div>
    <slot class="hidden"></slot>
</div>
`

@customElement({
  name: 'profile-menu-item',
  template,
  styles
})
export class ProfileMenuItem extends FASTElement {
  @attr selected: string | null = null;
  @attr actionable: string | null = null;
  @attr value: string | null;

  constructor() {
    super();
    this.addEventListener('click', this.itemClicked.bind(this))
  }

  itemClicked(): boolean {
    // if actionable, then emit the event in the event
    if (this.actionable) {
      this.$emit(this.actionable, { sender: this, componentPath: this.actionable})
      // console.debug('emitted actionable event')  
      return true;
    }
    console.debug('item clicked')
    this.$emit('item-clicked', { that: this })
    return true;
  }
}

