import { FASTElement, css, customElement, html, observable } from "@microsoft/fast-element";
import { ToastInfo, ToastType } from "@glideroggan/common"

const templateToastInfo = html/*html*/`
<svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
        <g>
            <path
                d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z">
            </path>
        </g>
    </g>
</svg>
`

const templateToastError = html/*html*/`
<svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 301.691 301.691" style="enable-background:new 0 0 301.691 301.691;"
    xml:space="preserve">
    <g>
        <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0  "></polygon>
        <rect x="130.563" y="261.168" width="40.525" height="40.523"></rect>
    </g>
</svg>
`

const templateToast = html<ToastItem>/*html*/`
<div main 
    class="toast ${x => x.info.type === ToastType.Info ? 'toast--green' : 'toast--yellow'}">
    <div icon class="toast__icon">
        ${x => x.info.type == ToastType.Error ? templateToastError : templateToastInfo}
    </div>
    <div class="toast__content">
        <p type class="toast__type">${x => x.info.type == ToastType.Error ? 'Warning' : 'Info'}</p>
        <p message class="toast__message">${x => x.info.message}</p>
    </div>
    <div closeBtn class="toast__close">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642"
            xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
            <path fill-rule="evenodd"
                d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z">
            </path>
        </svg>
    </div>
</div>
`

const styles = css`
.toast__cell {
    display: inline-block;
}
.toast__svg {
    fill: #fff;
}

.toast {
    text-align: left;
    padding: 21px 0;
    background-color: #fff;
    border-radius: 4px;
    max-width: 500px;
    top: 0px;
    position: relative;
    z-index: 1000;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
}


.toast:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

}

.toast__icon {
    position: absolute;
    top: 50%;
    left: 22px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    padding: 7px;
    border-radius: 50%;
    display: inline-block;
}

.toast__type {
    color: #3e3e3e;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
}

.toast__message {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    color: #878787;
}

.toast__content {
    padding-left: 70px;
    padding-right: 60px;
}

.toast__close {
    position: absolute;
    right: 22px;
    top: 50%;
    width: 14px;
    cursor: pointer;
    height: 14px;
    fill: #878787;
    transform: translateY(-50%);
}

.toast--green .toast__icon {
    background-color: #2BDE3F;
}

.toast--green:before {
    background-color: #2BDE3F;
}

.toast--blue .toast__icon {
    background-color: #1D72F3;
}

.toast--blue:before {
    background-color: #1D72F3;
}

.toast--yellow .toast__icon {
    background-color: #FFC007;
}

.toast--yellow:before {
    background-color: #FFC007;
}

`

@customElement({
    name: 'toast-item',
    template: templateToast,
    styles: styles
})
export class ToastItem extends FASTElement {
    @observable info: ToastInfo
    @observable ready: boolean = false;

    connectedCallback(): void {
        super.connectedCallback();
        setTimeout(() => {
            this.classList.add('fade-out')
            setTimeout(() => {
                this.remove();
            }, 2000);
        }, 2000);
    }
}