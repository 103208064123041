import { IShellServices, StateService, ToastInfo } from "@glideroggan/common";
import { FASTElement, Observable, customElement, observable } from "@microsoft/fast-element";
import { styles, template } from "./templates";
import { ToastItem } from "./toastItem";
ToastItem

@customElement({
    name: 'toast-com',
    template: template,
    styles: styles
})
export class Toast extends FASTElement {
    @observable translations: any = []
    @observable toasts: ToastInfo[] = []
    constructor() {
        super();
        StateService.language.languageChange.on(this.languageChange.bind(this))
        StateService.shellConnected.on(async (services?: IShellServices) => {
            // console.debug('[Toast] shell connected')
            services!.toast.on(this.handleIncomingToasts.bind(this))
        })
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();
        const lang = StateService.language.getLanguage();
        this.translations = await StateService.language.getTranslations(lang);
    }
    private async languageChange(lang: string | undefined) {
        this.translations = await StateService.language.getTranslations(lang!);
        Observable.notify(this, 'translations')
      }
    handleIncomingToasts(data: ToastInfo | undefined): void {
        if (!data) return

        data.message = this.translations[data!.message] || data!.message
        this.toasts.push(data!)
    }
}