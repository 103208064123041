import { ITaskInfo } from "@glideroggan/common";
import { StateService } from "@glideroggan/common";
import { FASTElement, css, customElement, html, observable, repeat } from "@microsoft/fast-element";

const template = html<TaskSearcher>`
<style>
    @import url("assets/css/font-awesome.min.css");
</style>
<div>
    <form @submit="${(x, e) => x.addTask(e.event as SubmitEvent)}">
        <div class="input-group">
            <div slot="input" class="search-field">
                <i class="fa fa-search"></i>
                <input class="form-input" type="text" id="tasks" name="task search" list="results"
                    placeholder="search" autocomplete="results" />
                <datalist id="results">
                    ${repeat(x => x.tasks, html<ITaskInfo>`<option value="${x => x.title}"></option>`)}
                </datalist>
            </div>
        </div>
    </form>
</div>
`

const styles = css`
form {
    /* width: 30%; */
}

.form-input {
    border: 2px solid #c4c4c4;
    padding: 10px 16px;
    background-color: var(--form-background-color);
    font-family: var(--body-font);
    border-radius: var(--button-border-radius);
    outline: none;
    font-size: 15px;
    transition: 0.3s;
}

.search-field {
    position: relative;
}

.search-field i {
    position: absolute;
    right: 0;
    padding: 10px 12px;
    pointer-events: none;
}

.input-group {
    position: relative;
    width: 50%;
    /* display: flex; */
    /* align-items: center; */
}

.hidden {
    display: none;
}

ul {
    margin: 0;
    padding: 0;
}

.input-group ul {
    position: absolute;
    top: 100%;
    left: 0;
    /* width: 100%; */
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-top: none;
    border-radius: 0 0 5px 5px;
    /* padding: 0 1rem 0 0; */
    /* margin: 0 1rem 0 0; */
    list-style: none;
    z-index: 1;
}

li:hover {
    background-color: #e5e5e5;
    cursor: pointer;
}
`

@customElement({
    name: 'task-searcher',
    template,
    styles
})
export class TaskSearcher extends FASTElement {
    @observable tasks: ITaskInfo[] = [];
    @observable ready: boolean = false;

    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        this.tasks = await StateService.tasks.getTasks(this)

        this.ready = true;
    }
    addTask(e: SubmitEvent) {
        const target = e.target as HTMLFormElement;
        const task = target.elements.namedItem('tasks') as HTMLInputElement;
        const ev = new CustomEvent('task-added', { detail: {title: task.value}, bubbles: true, composed: true });
        this.dispatchEvent(ev);
        return false
    }
}