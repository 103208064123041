import { css } from "@microsoft/fast-element";

// import { baseCss } from '../../styles'
export const styles = css/*css*/`
@charset "UTF-8";
    button,
    a {
      cursor: pointer;
    }

    .app-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: var(--app-container);
      max-width: 1800px;
    }

    .app-container button,
    .app-container input,
    .app-container optgroup,
    .app-container select,
    .app-container textarea {
      font-family: var(--main-font);
    }

    .app-content {
      display: flex;
      height: 100%;
      overflow: hidden;
      padding: 16px 24px 24px 0;
    }

    .app-header {
      display: flex;
      justify-content: right;
      background-color: var(--app-container);
      // align-items: center;
      // width: 100%;
      position: relative;
    }

    .app-header-left,
    .app-header-right {
      display: flex;
      align-items: center;
      justify-content: right;
    }

    .app-header-left {
      // flex-grow: 1;
      
      align-self: left;
      align-items: left;
      margin-right: auto;
    }

    .app-header-right {
      // margin-left: auto;
    }

    .app-icon {
      width: 26px;
      height: 2px;
      border-radius: 4px;
      background-color: var(--icon-color);
      position: relative;
    }

    .app-icon:before,
    .app-icon:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 2px;
      border-radius: 4px;
      background-color: var(--icon-color);
      left: 50%;
      transform: translatex(-50%);
    }

    .app-icon:before {
      top: -6px;
    }

    .app-icon:after {
      bottom: -6px;
    }

    .app-name {
      color: var(--text-color);
      font-family: var(--main-font);
      font-family: 'Sofia', sans-serif;
      margin: 0;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      margin-right: 32px;
      & > p {
        margin: 0;
      }
    }

    .mode-switch {
      background-color: transparent;
      border: none;
      padding: 0;
      color: var(--icon-color);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .search-wrapper {
      border-radius: 20px;
      background-color: var(--search-area-bg);
      padding-right: 12px;
      height: 40px;
      display: none;
      justify-content: space-between;
      align-items: center;
      max-width: 480px;
      color: var(--light-font);
      box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
        0 24px 20px -24px rgba(71, 82, 107, 0.1);
      overflow: hidden;
    }

    .dark .search-wrapper {
      box-shadow: none;
    }

    .search-input {
      border: none;
      flex: 1;
      outline: none;
      height: 100%;
      padding: 0 20px;
      font-size: 16px;
      background-color: var(--search-area-bg);
      color: var(--main-color);
    }

    .search-input:placeholder {
      color: var(--main-color);
      opacity: 0.6;
    }

    .add-btn {
      color: #fff;
      background-color: var(--button-bg);
      padding: 0;
      border: 0;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .notification-btn {
      color: var(--icon-color);
      padding: 0;
      border: 0;
      background-color: transparent;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-btn {
      padding: 0;
      border: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-left: 2px solid #ddd;
    }

    .btn-icon {
      color: var(--icon-color);
    }

    .profile-btn img {
      width: 32px;
      height: 32px;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 4px;
    }

    .profile-btn span {
      color: var(--main-color);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    .page-content {
      flex: 1;
      width: 100%;
    }

    .app-sidebar {
      padding: 40px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .app-sidebar-link {
      color: var(--main-color);
      color: var(--link-color);
      margin: 16px 0;
      transition: 0.2s;
      border-radius: 50%;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .app-sidebar-link:hover {
      background-color: var(--link-color-hover);
      color: var(--link-color-active);
    }

    .app-sidebar-link.active {
      background-color: var(--link-color-active-bg);
      color: var(--link-color-active);
    }

    .projects-section {
      flex: 2;
      background-color: var(--projects-section);
      border-radius: 32px;
      padding: 32px 32px 0 32px;
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .projects-section-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 32px;
    }

    .projects-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      color: var(--main-color);
    }

    .projects-section-header p {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      opacity: 0.9;
      margin: 0;
      color: var(--main-color);
    }

    .projects-section-header .time {
      font-size: 20px;
    }

    .projects-status {
      display: flex;
    }

    .item-status {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
    }

    .item-status:not(:last-child) .status-type:after {
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translatey(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 1px solid var(--secondary-color);
    }

    .status-number {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      color: var(--main-color);
    }

    .status-type {
      position: relative;
      padding-right: 24px;
      color: var(--secondary-color);
    }

    .view-actions {
      display: flex;
      align-items: center;
    }

    .view-btn {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      border-radius: 4px;
      background-color: transparent;
      border: none;
      color: var(--main-color);
      margin-left: 8px;
      /* transition: 0.2s; */
    }

    .view-btn.active {
      background-color: var(--link-color-active-bg);
      color: var(--link-color-active);
    }

    .view-btn:not(.active):hover {
      background-color: var(--link-color-hover);
      color: var(--link-color-active);
    }

    /* .messages-section {
            flex-shrink: 0;
            padding-bottom: 32px;
            background-color: var(--projects-section);
            margin-left: 24px;
            width: 100%;
            border-radius: 30px;
            position: relative;
            overflow: auto;
            transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
        } */

    .messages-section .messages-close {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 3;
      border: none;
      background-color: transparent;
      color: var(--main-color);
      display: none;
    }

    .messages-section.show {
      transform: translateX(0);
      opacity: 1;
      margin-left: 0;
    }

    .messages-section .projects-section-header {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 32px 24px 0 24px;
      background-color: var(--projects-section);
    }

    .message-box {
      border-top: 1px solid var(--message-box-border);
      padding: 16px;
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    .message-box:hover {
      background-color: var(--message-box-hover);
      border-top-color: var(--link-color-hover);
    }

    .message-box:hover + .message-box {
      border-top-color: var(--link-color-hover);
    }

    .message-box img {
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
      width: 40px;
      height: 40px;
    }

    .message-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .message-header .name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: var(--main-color);
      margin: 0;
    }

    .message-content {
      padding-left: 16px;
      width: 100%;
    }

    .star-checkbox input {
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
    }

    .star-checkbox label {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .dark .star-checkbox {
      color: var(--secondary-color);
    }

    .dark .star-checkbox input:checked + label {
      color: var(--star);
    }

    .star-checkbox input:checked + label svg {
      fill: var(--star);
      /* transition: 0.2s; */
    }

    .message-line {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;
      color: var(--secondary-color);
      opacity: 0.7;
    }

    .message-line.time {
      text-align: right;
      margin-bottom: 0;
    }

    .project-boxes {
      margin: 0 -8px;
      overflow-y: auto;
    }

    .project-boxes.jsGridView {
      display: flex;
      flex-wrap: wrap;
    }

    .project-boxes.jsGridView .project-box-wrapper {
      width: 33.3%;
    }

    .project-boxes.jsListView .project-box {
      display: flex;
      border-radius: 10px;
      position: relative;
    }

    .project-boxes.jsListView .project-box > * {
      margin-right: 24px;
    }

    .project-boxes.jsListView .more-wrapper {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .project-boxes.jsListView .project-box-content-header {
      order: 1;
      max-width: 120px;
    }

    .project-boxes.jsListView .project-box-header {
      order: 2;
    }

    .project-boxes.jsListView .project-box-footer {
      order: 3;
      padding-top: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    .project-boxes.jsListView .project-box-footer:after {
      display: none;
    }

    .project-boxes.jsListView .participants {
      margin-bottom: 8px;
    }

    .project-boxes.jsListView .project-box-content-header p {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .project-boxes.jsListView .project-box-header > span {
      position: absolute;
      bottom: 16px;
      left: 16px;
      font-size: 12px;
    }

    .project-boxes.jsListView .box-progress-wrapper {
      order: 3;
      flex: 1;
    }

    .project-box {
      --main-color-card: #dbf6fd;
      border-radius: 30px;
      padding: 16px;
      background-color: var(--main-color-card);
    }

    .project-box-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      color: var(--main-color);
    }

    .project-box-header span {
      color: #4a4a4a;
      opacity: 0.7;
      font-size: 14px;
      line-height: 16px;
    }

    .project-box-content-header {
      text-align: center;
      margin-bottom: 16px;
    }

    .project-box-content-header p {
      margin: 0;
    }

    .project-box-wrapper {
      padding: 8px;
      /* transition: 0.2s; */
    }

    .project-btn-more {
      padding: 0;
      height: 14px;
      width: 24px;
      position: relative;
      background-color: transparent;
      border: none;
      flex-shrink: 0;
    }

    .more-wrapper {
      position: relative;
    }

    .box-content-header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      opacity: 0.7;
    }

    .box-content-subheader {
      font-size: 14px;
      line-height: 24px;
      opacity: 0.7;
    }

    .box-progress {
      display: block;
      height: 4px;
      border-radius: 6px;
    }

    .box-progress-bar {
      width: 100%;
      height: 4px;
      border-radius: 6px;
      overflow: hidden;
      background-color: #fff;
      margin: 8px 0;
    }

    .box-progress-header {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      margin: 0;
    }

    .box-progress-percentage {
      text-align: right;
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }

    .project-box-footer {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      position: relative;
    }

    .project-box-footer:after {
      content: '';
      position: absolute;
      background-color: rgba(255, 255, 255, 0.6);
      width: calc(100% + 32px);
      top: 0;
      left: -16px;
      height: 1px;
    }

    .participants {
      display: flex;
      align-items: center;
    }

    .participants img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .participants img:not(:first-child) {
      margin-left: -8px;
    }

    .add-participant {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: none;
      background-color: rgba(255, 255, 255, 0.6);
      margin-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    .days-left {
      background-color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      border-radius: 20px;
      flex-shrink: 0;
      padding: 6px 16px;
      font-weight: 700;
    }

    .mode-switch.active .moon {
      fill: var(--main-color);
    }

    .messages-btn {
      border-radius: 4px 0 0 4px;
      position: absolute;
      right: 0;
      top: 58px;
      background-color: var(--message-btn);
      border: none;
      color: var(--main-color);
      display: none;
      justify-content: center;
      align-items: center;
      padding: 4px;
    }

    @media screen and (max-width: 980px) {
      .project-boxes.jsGridView .project-box-wrapper {
        width: 50%;
      }

      .app-header-left {
        display:none;
      }

      .status-number,
      .status-type {
        font-size: 14px;
      }

      .status-type:after {
        width: 4px;
        height: 4px;
      }

      .item-status {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 880px) {
      .messages-section {
        transform: translateX(100%);
        position: absolute;
        opacity: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
      }

      .messages-section .messages-close {
        display: block;
      }

      .messages-btn {
        display: flex;
      }
    }

    @media screen and (max-width: 720px) {
      .app-name,
      .profile-btn span {
        // display: none;
        // font-size: 10px;
      }

      .add-btn,
      .notification-btn,
      .mode-switch {
        // width: 20px;
        // height: 20px;
      }

      .add-btn svg,
      .notification-btn svg,
      .mode-switch svg {
        // width: 16px;
        // height: 16px;
      }

      .app-header-right button {
      }
    }

    @media screen and (max-width: 520px) {
      .projects-section {
        overflow: auto;
      }

      .project-boxes {
        overflow-y: visible;
      }

      .app-sidebar,
      .app-icon {
        display: none;
      }

      .app-content {
        padding: 16px 12px 24px 12px;
      }

      .status-number,
      .status-type {
        font-size: 10px;
      }

      .view-btn {
        width: 24px;
        height: 24px;
      }

      .app-header {
        
        padding: 0px 16px 0px 0px;
      }

      .app-name {
        display: none;
      }

      .search-input {
        max-width: 120px;
      }

      .project-boxes.jsGridView .project-box-wrapper {
        width: 100%;
      }

      /* .projects-section {
                padding: 24px 16px 0 16px;
            } */

      .profile-btn img {
        width: 24px;
        height: 24px;
      }

      /* .app-header {
                padding: 10px;
            } */

      .projects-section-header p,
      .projects-section-header .time {
        font-size: 18px;
      }

      .status-type {
        padding-right: 4px;
      }

      .status-type:after {
        display: none;
      }

      /* .search-input {
                font-size: 14px;
            } */

      .messages-btn {
        top: 48px;
      }

      .box-content-header {
        font-size: 12px;
        line-height: 16px;
      }

      .box-content-subheader {
        font-size: 12px;
        line-height: 16px;
      }

      .project-boxes.jsListView .project-box-header > span {
        font-size: 10px;
      }

      .box-progress-header {
        font-size: 12px;
      }

      .box-progress-percentage {
        font-size: 10px;
      }

      .days-left {
        font-size: 8px;
        padding: 6px 6px;
        text-align: center;
      }

      .project-boxes.jsListView .project-box > * {
        margin-right: 10px;
      }

      .project-boxes.jsListView .more-wrapper {
        right: 2px;
        top: 10px;
      }
    }

    .hidden {
      display: none !important;
    }

    @media only screen and (min-width: 1200px) {
      .search-wrapper {
        display: flex;
      }
      .app-header {
        justify-content: space-between;
      }
    }
    .app-version {
      font-size: 12px; /* Smaller font size for version number */
      opacity: 0.5; /* Makes the version number less prominent */
      margin-left: 8px; /* Spacing between the title and version number */
      vertical-align: super; /* Aligns the version number a bit higher */
      color: var(--text-color);
    }
    user-card {
      position: relative;
      width: 48px;
      // margin-left: 16px;
      // padding-left: 16px;
      border-left: 2px solid #ddd;
      pointer-events: auto;
      cursor: pointer;
    }

    modal-wrapper {
      
    }
`