import { html, when } from "@microsoft/fast-element";
import { Header } from "./header";

export const template = html<Header>/*html*/`
<style>
    :host {
        
    }
</style>
<div class="app-header ${x => !x.loggedIn ? 'hidden' : ''}">
    <div class="app-header-left">
        <div class="app-name">
            <p>${x => x.translations['TITLE_NAME']}</p>
            <span class="app-version">v4.0.0-dashboard</span>
        </div>
        <div class="search-wrapper">
            <input class="search-input" type="text" />
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-search"
                viewBox="0 0 24 24">
                <defs></defs>
                <circle cx="11" cy="11" r="8"></circle>
                <path d="M21 21l-4.35-4.35"></path>
            </svg>
        </div>
    </div>
    <div class="app-header-right">
        <button dark @click="${x => x.toggleDark()}" class="mode-switch" title="Switch Theme">
            <svg class="moon" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" width="24" height="24" viewBox="0 0 24 24">
                <defs></defs>
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
            </svg>
        </button>
        <button class="add-btn" title="Add New Project">
            <svg class="btn-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-plus">
                <line x1="12" y1="5" x2="12" y2="19" />
                <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
        </button>
        <slot name="notification" notification >notifications</slot>
        <profile-menu class="hidden"></profile-menu>
        
        ${when(x => x.loggedIn, html<Header>/*html*/`
            <user-card 
                @click="${x => x.toggleProfileMenu()}"
                :user="${x => x.user}"></user-card>
        `)}
    </div>
</div>
`