import { FASTElement, attr, css, customElement, html, observable } from '@microsoft/fast-element';
import { ProfileMenuItem } from './profileMenuItem'
ProfileMenuItem
import { StateService, IProfileMenuData } from '@glideroggan/common'


const styles = css`
:host {
    --speed: .25s;
    --width: 10rem;
    --containerWidth: 0rem;
    --surfaceHeight: 100px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    position: absolute;
    width: var(--width);
    /* height: 50px; */
    background-color: var(--secondary-background-color);
    display: flex;
    right: 160px;
    top: 1rem;
    flex-direction: column;
    z-index: 10000;
    overflow: hidden;
}

.moveable-container {
    position: relative;
    height: calc(var(--surfaceHeight) + 32px);
    /* max-height: calc(var(--surfaceHeight) + 10px); */
    width: var(--containerWidth);
    max-width: var(--containerWidth);
}

.surface {
    display: flex;
    top: 0;
    flex-direction: column;
    position: absolute;
    width: var(--width);
    min-width: var(--width);
    /* justify-content: center; */
    align-items: center;
    transition: transform var(--speed) ease-in-out;
}

.surface profile-menu-item {
    position: relative;
    border-top: 1px solid var(--primary-background-color);
    border-bottom: 1px solid var(--primary-background-color);
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    min-width: var(--width);
    height: 2rem;
    padding: 10px 0;
    color: var(--text-color);
    background-color: var(--secondary-background-color);
    color: var(--text-secondary-color);

}
.surface profile-menu-item:hover {
    background-color: var(--primary-background-color);
}

.header {
    cursor: pointer;
    user-select: none;
    position: relative;
    border: 2px solid var(--primary-background-color);
    display: flex;
    height: 2rem;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    background-color: var(--secondary-background-color);
    color: var(--text-secondary-color);
}

.header::after {
    content: "◀️";
    position: absolute;
    left: 0;
}
`
const template = html/*html*/`
<div id="dropdown-menu" class="wrapper">
    <div class="moveable-container">
    </div>
</div>
<template id="surface">
    <div class="surface">
        <div class="header"></div>
    </div>
</template>
`

@customElement({
    name: 'profile-menu',
    template,
    styles
})
export class ProfileMenu extends FASTElement {
    @observable _data: IProfileMenuData;
    surfaceTemplate: HTMLTemplateElement;
    translateWidth: number;

    set data(val: IProfileMenuData) {
        this._data = val;
        // clear container
        const container = this.shadowRoot!.querySelector('.moveable-container') as HTMLElement
        container.innerHTML = ''
        this.setupMenu()
    }
    get data() {
        return this._data
    }
    connectedCallback(): void {
        super.connectedCallback();

        this.$emit('profile-menu-initialized')
        this.surfaceTemplate = this.shadowRoot?.querySelector('#surface') as HTMLTemplateElement;
    }
    async setupMenu() {
        // construct menu
        const data = this._data
        // find out the deepest level
        let deepestLevel = 0
        let maxItems = 0
        const findDeepestLevel = (items: IProfileMenuData[], level: number) => {
            if (level > deepestLevel) {
                deepestLevel = level
            }
            if (items.length > maxItems) {
                maxItems = items.length
            }
            for (const item of items) {
                if (item.items !== undefined) {
                    findDeepestLevel(item.items, level + 1)
                }
            }
        }
        findDeepestLevel(data.items!, 0)

        // calculate the height of the menu
        const surfaceHeight = (maxItems * ((16 * 2))) + 'px'
        this.translateWidth = 10
        const containerWidth = ((deepestLevel + 1) * this.translateWidth) + 'rem'


        this.style.setProperty('--surfaceHeight', surfaceHeight)
        this.style.setProperty('--containerWidth', containerWidth)

        // generate menu
        const lang = await StateService.language.getLanguage();
        const translations = await StateService.language.getTranslations(lang);
        this.translateMenuData(data, translations)
        this.generateMenu(data, null)
    }
    generateMenu(item: IProfileMenuData, parent: HTMLElement | null, level: number = 0) {
        // TODO: use the templating system
        // create surface
        const surface = this.surfaceTemplate.content.cloneNode(true) as HTMLElement
        const surfaceContainer = surface.querySelector('.surface') as HTMLDivElement
        if (parent !== null) {
            surfaceContainer.setAttribute('parentId', parent.id)
        }
        surfaceContainer.setAttribute('id', item.value.replace(/ /g, '-'))
        const tran = 'transform: translateX(' + (level * this.translateWidth) + 'rem);'

        // listen to clicks in the surface from items
        surfaceContainer.addEventListener('item-clicked', this.itemClicked.bind(this))
        surfaceContainer.setAttribute('style', tran)

        // create header
        const header = surface.querySelector('.header') as HTMLElement
        header.textContent = item.value
        header.addEventListener('click', this.previousMenu.bind(this))

        for (const subItem of item.items!) {
            const n = document.createElement('profile-menu-item') as ProfileMenuItem
            n.setAttribute('value', subItem.value)
            if (subItem.actionable !== undefined) {
                n.setAttribute('actionable', subItem.actionable!)
            }
                
            if (subItem.selected) {
                n.setAttribute('selected', '')
            }
            surfaceContainer.appendChild(n)
            if (subItem.items !== undefined && subItem.items.length > 0) {
                this.generateMenu(subItem, surfaceContainer, level + 1)
            }
        }
        this.shadowRoot!.querySelector('.moveable-container')!.appendChild(surface)
    }
    translateMenuData(data: IProfileMenuData, translations: any) {
        // console.log('translateMenuData: ', data, translations)
        const walk = (item: IProfileMenuData) => {
            item.value = translations[item.id]
            if (item.value === undefined) throw new Error('item.value is undefined')
            for (const subItem of item.items!) {
                if (subItem.items !== undefined) {
                    walk(subItem)
                } else {
                    // TODO: fix so that we can at compile time know that "translations[subItem.id]" is invalid
                    // as translations is an "any"
                    subItem.value = translations[subItem.id]
                    if (subItem.value === undefined) throw new Error('subItem.value is undefined')
                }
            }
        }
        walk(data)
    }
    previousMenu(e: Event) {
        e.stopPropagation()
        // move back to the previous surface
        const currentSurface = this.shadowRoot!.querySelector('[style*="translateX(0"]') as HTMLElement
        const parentId = currentSurface.getAttribute('parentId')
        if (parentId === null) {
            this.classList.add('hidden')
            return
        }

        // const tran = 'transform: translateX(8rem);'
        const tran = `transform: translateX(${this.translateWidth}rem);`
        currentSurface.setAttribute('style', tran)
        // previous surface, get current surface parentId, and find that surface
        const previousSurface = this.shadowRoot!.querySelector('#' + parentId) as HTMLElement
        previousSurface.setAttribute('style', 'transform: translateX(0rem);')

        // console.log('surfaces: ', currentSurface, previousSurface)
    }
    async itemClicked(evt: Event) {
        // evt.stopPropagation()
        const e = evt as CustomEvent

        // first, lets find out if we're switching surface or selecting an item
        const item = e.detail.that as ProfileMenuItem
        // check the data-structure for the value of the item, if it has children, we need to switch surface
        // if not, we need to select the item
        const data = this._data
        const found = ProfileMenu.findItem(data.items!, item.getAttribute('value')!)
        // console.log('found: ', found)
        if (found === null) return
        if (found.item.items !== undefined && found.item.items.length > 0) {
            // switch surface
            // move the relevant surface into view
            // console.log('moveNextSurface: ', e)
            this.moveNextSurface(e)
        } else {
            // set element to selected
            await this.actOnItemClicked(item, found as { item: IProfileMenuData, parent: IProfileMenuData })
        }
    }
    private static findItem = (items: IProfileMenuData[], value: string, parent?: IProfileMenuData): { item: IProfileMenuData, parent?: IProfileMenuData } | null => {
        for (const item of items) {
            if (item.value === value) {
                return { item: item, parent: parent }
            }
            if (item.items !== undefined) {
                const found = ProfileMenu.findItem(item.items, value, item)
                if (found !== null) {
                    return found
                }
            }
        }
        return null
    }
    async actOnItemClicked(el: ProfileMenuItem, found: { item: IProfileMenuData, parent: IProfileMenuData }) {
        if (found.parent !== undefined) {
            // deselect all the data items for that parent
            found.parent.items!.forEach((item: IProfileMenuData) => {
                item.selected = false
            })
        }

        // deselect all children of the current surface
        const currentSurface = this.shadowRoot!.querySelector('[style*="translateX(0"]') as HTMLElement
        currentSurface.querySelectorAll('profile-menu-item').forEach((el: Element) => {
            const item = el as ProfileMenuItem
            item.removeAttribute('selected')
            // item.selected = false
        })
        el.setAttribute('selected', '')
        // el.selected = true

        // set data item to selected
        found.item.selected = true
        const handler = await StateService.getHandler(found.item)
        // console.debug(`[ProfileMenu:actOnItemClicked] handler: `, handler, found.item)
        await handler(found.item)
    }
    moveNextSurface(e: CustomEvent) {
        const nextSurface = this.shadowRoot!.querySelector('#' + e.detail.that.getAttribute('value').replace(/ /g, '-')) as HTMLElement
        console.log('nextSurface: ', nextSurface)

        // transform the current surface (that have 0 transform) to the left
        // and pull in the next surface, by changing that surface transform to 0
        const currentSurface = this.shadowRoot!.querySelector('[style*="translateX(0"]') as HTMLElement
        // const tran = 'transform: translateX(-8rem);'
        const tran = `transform: translateX(-${this.translateWidth}rem);`
        currentSurface.setAttribute('style', tran)
        nextSurface.setAttribute('style', 'transform: translateX(0);')
    }

    
}