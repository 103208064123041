import { RouteInfo, StateService } from "@glideroggan/common"
import { FASTElement, css, customElement, html, observable, repeat } from "@microsoft/fast-element";

const itemTemplate = html<RouteInfo>/*html*/`
<style>
    i {
        color: var(--icon-color);
    }
    a:hover, a:focus, a:active, a:visited, a:link, a {
        text-decoration: none;
        color: unset; 
        background-color: none;
    }
</style>
<li>
    <div href="#${x => x.route}" @click="${(x, e) => e.parent.navigate('#' + x.route)}">
        <i icon class="fa fa-2x ${x => x.classList}"></i>    
        <span text class="nav-text">
            ${x => x.route}
        </span>
    </div>
</li>
`

const template = html<Menu>/*html*/`
<style>
    @import url("assets/css/font-awesome.min.css");
    @import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);
</style>
<nav class="main-menu ${x => x.hidden ? " hidden" : "" }">
    <ul id="menu">
        ${repeat(x => x.routes, itemTemplate)}
    </ul>
    <ul class="logout">
        <li>
            <div link href="#logout" @click=${x=> x.$emit('logout')}>
                <i class="fa fa-power-off fa-2x"></i>
                <span text class="nav-text">
                    Logout
                </span>
            </div>
        </li>

    </ul>
</nav>
`

const styles = css`
/*@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);*/

/* :host {
    --background-color: var(--primary-background-color);
} */

.fa-2x {
    font-size: 2em;
}

/* :host-context(body.dark-theme) {
    --background-color: var(--secondary-background-color);
} */

.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}


.main-menu {
    display: flex;
    background-color: var(--secondary-background-color);
    /* border-right: 1px solid #e5e5e5; */
    top: 0;
    width: 100%;
    left: 0;
    height: 44px;

    overflow: hidden;
    -webkit-transition: width .05s linear;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 1000;
    justify-content: space-between;
}

.main-menu>ul {
    margin: 7px 0;
    display: inline-flex;
    :last-child {
        align-self: flex-end;
    }
}


.main-menu li {
    position: relative;
    display: block;
    @media (min-width: 800px) {
        width: 40px;
    }
    cursor: pointer;
}

.main-menu li>div {
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: #999;
    font-family: arial;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    -webkit-transition: all .1s linear;
    transition: all .1s linear;

}

.main-menu .nav-icon {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}

.main-menu .nav-text {
    position: relative;
    display: none;
    vertical-align: middle;
    width: 190px;
    font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {

    /* align-self: flex-end; */
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

a:hover,
a:focus {
    text-decoration: none;
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
    color: #fff;
    background-color: #000000;
}

.area {
    float: left;
    background: var(--background-color);
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}

.hidden {
    display: none !important;
}

@media only screen and (min-width: 1200px) {
    .main-menu {
        display: block;
        width: 3em;
        bottom: 0;
        height: 100%;
    }

    .main-menu>ul {
        display: block;
    }

    .main-menu li {
        width: 250px;
    }

    .main-menu>ul.logout {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .main-menu .nav-text {
        display: table-cell;
    }
}
`

@customElement({
    name: 'app-menu',
    template,
    styles
})
export class Menu extends FASTElement {
    @observable ready: boolean = false;
    @observable hidden: boolean = true;
    @observable routes: RouteInfo[] = []

    constructor() {
        super();
        const that = this
        StateService.shellConnected.on(() => {
            // console.log('Menu: shell connected')
            
            StateService.users.userLogin.on(that.show.bind(that))
            StateService.users.userLogout.on(this.hide.bind(that))
        })
    }
    async navigate(page: string) {
        this.$emit('navigate', { page })
    }
    async show() {
        await this.updateRoutes()
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }
    async updateRoutes() {
        this.routes = await StateService.getRoutes(this) as RouteInfo[]
    }

    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        if (StateService.isLoggedIn) {
            await this.updateRoutes()
            this.hidden = false;
        }
        this.ready = true;
    }
}
