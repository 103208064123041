import { css, html, when } from "@microsoft/fast-element";
import { RouterView } from "./router-view";

const template404 = html<RouterView>`<h1>404 - Page not found</h1><p>${x => x.targetPage}</p>`
const errorPage = html<RouterView>/*html*/`
<style>
    h3 {
        margin: 0;
    }
    p {
        margin: 0;
    }
</style>
<h1>Error loading page</h1>
<h3>Endpoint</h3>
<p>${x => x.error.endpoint}</p>
<h3>Tag</h3>
<p>${x => x.error.routeElement}</p>
<h3>Exposes</h3>
<p>${x => x.error.exposes}</p>
<h3>Error</h3>
<p>${x => x.error.error}</p>
`
export const styles = css`
:host {
    --background-color: var(--primary-background-color);
    --success-color: #1eb9c6;
    --success-dark-color: #d2f1f4;
    height:100%;
    min-height: 100%;
}

[content] {
    position: relative;
    display: block;
    height: 100%;
    min-height: 100%;
    background-color: var(--background-color);
    border-radius: 20px;
}
[innercontent] {
    height: 100%;
    min-height: 100%;
    display: block;
    & * {
        display: block;
        height: 100%;
        min-height: 100%;
    }
}
]
.loader-wrapper {
    position: absolute;
    display: flex;
    max-height: 20px;
    background-color: var(--background-color);
    flex-direction: column;
    align-items: center;
}
.hidden {
    display: none !important;
}
`
const loader = html/*html*/`
<style>
    .loader {
        width: 100%;
        height: 4.8px;
        display: inline-block;
        position: relative;
        background: rgba(255, 255, 255, 0.15);
        overflow: hidden;
    }

    .loader::after {
        content: '';
        width: 192px;
        height: 4.8px;
        background: var(--text-color);
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        animation: animloader 2s linear infinite;
    }

    @keyframes animloader {
        0% {
            left: 0;
            transform: translateX(-100%);
        }

        100% {
            left: 100%;
            transform: translateX(0%);
        }
    }
</style>
<span spinner class="loader"></span>
`
const loadedContent = html<RouterView>/*html*/`
<div innerContent :innerHTML="${x => x.HTML}" class="${x => x.state == 'loading' ? 'hidden' : ''}"></div>
`
export const template = html<RouterView>/*html*/`
<div loader>${when(x => x.state == 'loading', loader)}</div>
<div content class="${x => x.state == 'loading' ? 'hidden' : ''}">
    ${when(x => x.state == 'loaded', loadedContent)}
    ${when(x => x.state == 'error', errorPage)}
    ${when(x => x.state == 'notfound', template404)}
</div>


`