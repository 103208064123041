import { Notis, StateService } from "@glideroggan/common";
import { FASTElement, Observable, css, customElement, html, observable, repeat } from "@microsoft/fast-element";
import { baseCss, mainCss } from "../../styles";

interface NotisMethods extends Notis {
    messageClicked: (id: number, e: Event) => void
}

const itemTemplate = html<NotisMethods>/*html*/`
<li text data-id="${x => x.id}" 
    class="animate"
    @click="${(x, e) => x.messageClicked(x.id, e.event)}"><span>${x => x.message}</span><i class="fa fa-code float-right"></i></li>
`

const template = html<NotificationCom>/*html*/`
<button notification class="notification-btn"
    @click="${x => x.open()}">
    <div class="message-count ${x => x.messages.length > 0 ? '' : 'hidden'}">
        <span message-count>${x => x.messages.length}</span>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-bell">
        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
        <path d="M13.73 21a2 2 0 0 1-3.46 0" />
    </svg>
    <div dropdown class="dropdown ${x => x.dropdownOpen ? '' : 'hidden'}">
        <input id="toggle2" type="checkbox">
        <ul list class="animate">
            ${repeat(x => x.messages, itemTemplate)}
        </ul>
    </div>
</button>
`

const styles = css/*css*/`
${mainCss}
${baseCss}
.notification-btn {
    color: var(--icon-color);
    padding: 0;
    cursor: pointer;
    border: 0;
    // margin-left: 10px;
    background-color: transparent;
    height: 32px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.message-count {
    background-color: var(--message-count-bg);
    position: absolute;
    display: table-cell;
    float: right;
    right: -14px;
    top: -10px;
    opacity: 0.8;
    font-size: larger;
    padding: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: normal;

}

.message-count span {
    vertical-align: baseline;
    text-align: center;
    opacity: 1;
}

.hidden {
    display: none !important;
}

* {
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
}

.float-right {
    float: right;
}

.fa {
    font-size: 0.8em;
    line-height: 22px !important;
}

.dropdown {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    margin: 20px 50px;
    top: 35px;
    left: -170px;
    border-radius: 20px;
    z-index: 1000;
}

.dropdown label,
.dropdown ul li {
    display: block;
    width: 150px;
    background: #ECF0F1;
    padding: 15px 20px;
}

.dropdown label:hover,
.dropdown ul li:hover {
    background: #1ABC9C;
    color: white;
    cursor: pointer;
}

.dropdown input {
    display: none;
}

.dropdown input~ul {
    position: relative;
    /* visibility: hidden; */
    /* opacity: 0; */
    top: -20px;
    z-index: 1;
}

.dropdown input:checked+label {
    background: #1ABC9C;
    color: white;
}

.dropdown input:checked~ul {
    visibility: visible;
    opacity: 1;
    top: 0;
}

.dropdown ul li:nth-child(1) {
    border-left: 4px solid #E74C3C;
}

.dropdown ul li:nth-child(1) .fa {
    color: #E74C3C;
}

.dropdown ul li:nth-child(1):hover {
    background: #E74C3C;
    color: white;
}

.dropdown ul li:nth-child(1):hover .fa {
    color: white;
}

.dropdown ul li:nth-child(2) {
    border-left: 4px solid #0072B5;
}

.dropdown ul li:nth-child(2) .fa {
    color: #0072B5;
}

.dropdown ul li:nth-child(2):hover {
    background: #0072B5;
    color: white;
}

.dropdown ul li:nth-child(2):hover .fa {
    color: white;
}

.dropdown ul li:nth-child(3) {
    border-left: 4px solid #2C3E50;
}

.dropdown ul li:nth-child(3) .fa {
    color: #2C3E50;
}

.dropdown ul li:nth-child(3):hover {
    background: #2C3E50;
    color: white;
}

.dropdown ul li:nth-child(3):hover .fa {
    color: white;
}

.animate {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    /* Chrome and Safari */
    -moz-backface-visibility: hidden;
    /* Firefox */
    -ms-backface-visibility: hidden;
    /* Internet Explorer */
}
dialog {
    position: absolute;
    margin: auto;
    padding: 1rem;
}
`

@customElement({
    name: 'notification-com',
    template: template,
    styles
})
export class NotificationCom extends FASTElement {
    @observable ready: boolean = false
    @observable messages: NotisMethods[] = []
    @observable dropdownOpen: boolean = false
    constructor() {
        super();

        StateService.shellConnected.on(() => {
            StateService.users.userLogin.on(this.handleLogin.bind(this))
            StateService.users.userLogout.on(this.handleLogout.bind(this))
        })

    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        if (StateService.currentUser !== null) {
            await this.checkMessages()
        }

        this.ready = true
    }
    async disconnectedCallback(): Promise<void> {
        super.disconnectedCallback();
        StateService.users.userLogin.off(this.handleLogin.bind(this))
        StateService.users.userLogout.off(this.handleLogout.bind(this))
        StateService.notifications.unsubscribe()
    }
    open() {
        this.dropdownOpen = !this.dropdownOpen
        if (this.messages.length == 0) {
            this.dropdownOpen = false
        }
    }

    async checkMessages() {
        const callback = (data: Notis) => {
            if (this.messages.find(x => x.id == data.id) == null) {
                this.messages.push({
                    ...data,
                    messageClicked: this.messageClicked.bind(this)
                })
                Observable.notify(this, 'messages')
            }
        }
        StateService.notifications.subscribe(this, callback)
    }
    async messageClicked(id: number, e: Event) {
        const target = e.target as HTMLElement
        const message = this.messages.find(x => x.id == id)
        const handler = StateService.notifications.getHandler(message!.type)
        if (handler != null) {
            if (!handler.isInitialized) {
                await handler.init(this.shadowRoot!)
            }
            await handler.handleNotification(this, this.shadowRoot!, message!, async (notificationId: number) => {
                target.remove()
                const newList = this.messages.filter(x => x.id != notificationId)
                this.messages = newList
            })
        } else {
            // remove from list
            const newList = this.messages.filter(x => x.id != id)
            this.messages = newList
            await StateService.notifications.deleteNotification(this, id)
        }
    }
    async handleLogout() {
        // console.log('handleLogout: notification')
        StateService.notifications.unsubscribe()
        // empty dropdown list
        this.messages = []
    }
    async handleLogin() {
        // console.log('handleLogin: notification')

        this.checkMessages()
    }
}