import '../../components/task-searcher/task-searcher';
import {
  Achievement,
  AchievementCondition,
  ConditionType,
  ITaskInfo,
  StateService,
} from '@glideroggan/common';
import { FASTElement, Observable, css, customElement, html, observable, repeat } from '@microsoft/fast-element';


const conditionItemTemplate = html/*html*/`
<li>
  <div>
    <span title>${x=>x.title}</span>
  </div>
  <!-- TODO: fix a button to delete a condition -->
  <div>
      <!-- TODO: handle input -->
    <input type="number" min=1 placeholder="1" /><label>or more times</label>
  </div>
</li>
`

const template = html<AchivementCreator>/*html*/`
<style>
  @import url('assets/css/base.css');
</style>
<div class="container">
  <div class="left">
    <div>
      <div class="form-block title">
        <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_TITLE']}</label>
        <!-- <input title class="form-input" type="text" placeholder="Baby eater" /> -->
        <input class="form-input" slot="input" title type="text" placeholder="Baby eater"
          @input="${(x, e) => x.handleTitleChange(e.event)}" />
      </div>
      <div class="difficulty">
        <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_DIFFICULTY']}</label>

        <svg star class="filled roger" @click="${x => x.starClicked(1)}" xmlns="http://www.w3.org/2000/svg" height="1em"
          viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
        </svg>

        <!-- 2 -->
        <svg star class="${x => x.stars > 1 ? 'star-hidden' : ''}" @click="${x => x.starClicked(2)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
        </svg>
        <svg star class="filled ${x => x.stars < 2 ? 'star-hidden' : ''}" @click="${x => x.starClicked(2)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
        </svg>

        <!-- 3 -->
        <svg star class="${x => x.stars > 2 ? 'star-hidden' : ''}" @click="${x => x.starClicked(3)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
        </svg>
        <svg star class="filled ${x => x.stars < 3 ? 'star-hidden' : ''}" @click="${x => x.starClicked(3)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
        </svg>

        <!-- 4 -->
        <svg star class="${x => x.stars > 3 ? 'star-hidden' : ''}" @click="${x => x.starClicked(4)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
        </svg>
        <svg star class="filled ${x => x.stars < 4 ? 'star-hidden' : ''}" @click="${x => x.starClicked(4)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
        </svg>

        <!-- 5 -->
        <svg star class="${x => x.stars > 4 ? 'star-hidden' : ''}" @click="${x => x.starClicked(5)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
        </svg>
        <svg star class="filled ${x => x.stars < 5 ? 'star-hidden' : ''}" @click="${x => x.starClicked(5)}"
          xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
          <style>
            svg {
              fill: #ee9f17
            }
          </style>
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
        </svg>
      </div>
    </div>

    <div class="form-block">
      <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_DESCRIPTION']}</label>
      <input-field>
        <textarea slot="input" description name="comment" class="form-input" maxlength="40"
          @input="${(x, e) => x.handleDescriptionChange(e.event)}" placeholder="Ta små portioner"></textarea>
      </input-field>

    </div>
    <div class="divider">
      <div class="form-block money">
        <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_REWARD']}</label>
        <input-field>
          <input slot="input" reward class="form-input" type="text" 
            @input="${(x, e) => x.handleRewardChange(e.event)}"
            placeholder="🍟McDonalds" />
        </input-field>

      </div>


    </div>
    <button create class="button button-success">${x => x.translations['ACHIEVEMENT_CREATOR_CREATE_BUTTON']}</button>
  </div>
  <div class="middle">
    <div>
      <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_LABEL_ADVANCED']}</label>
      <div>
        <div>
          <label class="form-label">${x => x.translations['ACHIEVEMENT_CREATOR_LABEL_COMPLETED']}</label>
          <task-searcher></task-searcher>
          <div class="condition-list">
            <ul condition-list>
              ${repeat(x => x.model.conditions, conditionItemTemplate)}
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="right">
    <achievement-card :model=${x=> x.model}></achievement-card>
  </div>
</div>
`

const styles = css`
.card-body {
  border: 2px solid #ddd;
  padding: 10px 16px;
  border-radius: var(--button-border-radius);
}

.card-image {
  height: 200px;
  overflow: hidden;
}

.card-image>img {
  width: 100%;
}

header img {
  width: 200px;
}

.title {
  width: 300px;
}

/* start */

.money {
  width: 150px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr .5fr;
  /* max-width: 100%; */
  /* margin: auto; */
}

.container .left {
  margin-right: 1rem;
  /* border: solid 1px #ddd; */
  /* padding: 1rem 1rem 1rem 0; */
}

.container .right {
  margin-left: 1rem;
  justify-self: end;
}


.img-preview img {
  width: 200px;
  height: 200px;
  display: inline-block;
  /* object-fit: contain; */
}

.roger {
  display: flex;
  box-sizing: border-box;
  gap: 20px;
}

.form-block+.roger {
  margin-top: 20px;
}

.difficulty {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.divider {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}

.hidden {
  display: none;
}

.star-hidden {
  pointer-events: none;
  display: none;
}

.difficulty svg path {
  pointer-events: none;
}

/* Condition list */
.condition-list {
  margin-top: 20px;
}

ul {
  all: unset;
}

.condition-list li {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
}

.condition-list input[type="number"] {
  width: 50px;
}
`
interface AchivementConditionsModel extends AchievementCondition {
  title: string;
}

interface AchivementCreatorModel extends Omit<Achievement, 'id' | 'conditions'> {
  // progressUserList: UserProgression[];
  conditions: AchivementConditionsModel[]
}



@customElement({
  name: 'achivement-creator',
  template,
  styles,
})
export class AchivementCreator extends FASTElement {
  @observable stars: number = 1;
  @observable translations: any = {};
  @observable model: AchivementCreatorModel = {
    title: 'Baby eater',
    description: 'Ta små portioner',
    reward: '🍟McDonalds',
    difficulty: 1,
    conditions: [
      {
        itemId: 1,
        title: 'Task 1',
        type: ConditionType.Task,
        times: 1,
      },
    ],
    progressUserList: [
      {
        userId: 1,
        progress: 20,
      },
    ],
  }
  constructor() {
    super();

    this.addEventListener('task-added', this.taskAdded.bind(this));
    StateService.language.languageChange.on(this.languageChanged.bind(this));
    
  }
  async languageChanged() {
    const translations = await StateService.language.getTranslations(await StateService.language.getLanguage())
    this.translations = translations;
    Observable.notify(this, 'translations');
  }
  async connectedCallback() {
    super.connectedCallback();

    await this.languageChanged();

    this.$emit('loaded');
  }
  taskAdded(e: Event) {
    const detail = e as CustomEvent;
    const task = detail.detail as ITaskInfo;
    // console.log('taskAdded: ', task);
    const condition: AchivementConditionsModel = {
      itemId: task.id,
      title: task.title,
      type: ConditionType.Task,
      times: 1,
    };
    this.model.conditions.push(condition);
    Observable.notify(this, 'model');
  }
  handleRewardChange(event: Event): any {
    const input = event.target as HTMLInputElement;
    this.model.reward = input.value;

    Observable.notify(this, 'model');
  }
  handleDescriptionChange(event: Event): any {
    const input = event.target as HTMLInputElement;
    this.model.description = input.value;

    Observable.notify(this, 'model');
  }
  handleTitleChange(event: Event): any {
    const input = event.target as HTMLInputElement;
    this.model.title = input.value;

    Observable.notify(this, 'model');
  }
  starClicked(num: number): any {
    this.stars = num;
    this.model.difficulty = num;
    Observable.notify(this, 'model');
  }
}


