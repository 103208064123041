import { css, html, repeat } from "@microsoft/fast-element";
import { Toast } from "./toast";
import { ToastInfo } from "@glideroggan/common";

export const styles= css`
// @import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i');

.toast__container {
    display: table-cell;
    vertical-align: middle;
}
.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}
`

export const template = html<Toast>`
<div class="toast__container">
    <div list class="toast__cell">
        ${repeat(x => x.toasts, html<ToastInfo>`<toast-item :info="${x => x}"></toast-item>`)}
    </div>
</div>
`